import React from "react"
import { Link } from "gatsby"

import Twitter from "../assets/images/icons/twitter.svg"
import Instagram from "../assets/images/icons/instagram.svg"
import Github from "../assets/images/icons/github.svg"
import LinkedIn from "../assets/images/icons/linkedin.svg"

export default function Header(props) {
  return (
    <header className="site-header">
      <div className="site-header__content">
        <div className="site-header__logo">
          <Link to="/">Joe Sanchez Jr</Link>
        </div>
        <div className="site-header__right">
          <div className="site-header__social-icons">
            <a href="https://instagram.com/joesanchezjr">
              <Instagram />
            </a>
            <a href="https://twitter.com/joesanchezjr">
              <Twitter />
            </a>
            <a href="https://github.com/joesanchezjr">
              <Github />
            </a>
            <a href="https://linkedin.com/in/joesanchezjr">
              <LinkedIn />
            </a>
          </div>
          <div className="site-header__bar" />
          <a
            href="mailto:joe@joesanchezjr.com"
            className="site-header__contact-button"
          >
            Contact
          </a>
        </div>
      </div>
    </header>
  )
}
