import React from "react"

export default function Footer(props) { 
  return (
  <footer className="site-footer">
    <p>
      <small>
        &copy; {new Date().getFullYear()} Joe Sanchez Jr. - All Rights Reserved.
      </small>
    </p>
  </footer>
)
}
